import React, { useEffect, useState } from 'react';
import { Button, Form, Header, Message, Table, Segment } from 'semantic';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { joiErrorDetailsToObject, request } from 'utils/api';
import { FieldArray, Formik } from 'formik';
import SelectField from 'components/form-fields/formik/SelectField';
import InputField from 'components/form-fields/formik/InputField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import DropdownField from 'components/form-fields/formik/DropdownField';
import { Provider } from 'types/provider';
import SearchDropdownField from 'components/form-fields/formik/SearchDropdown';
import { Divider } from 'semantic-ui-react';
import CountriesField from 'components/form-fields/formik/Countries';
import { get } from 'lodash-es';
import './ProviderForm.module.css';
import { countries } from 'eflux-pkg-js';
import { Group } from '../../Layout/helpers';
import TimeField from 'components/form-fields/formik/TimeField';
import FeatureFlag from 'components/FeatureFlag';

interface CurrenciesFieldProps {
  name: string;
  enabled?: boolean;
}

const ProviderCurrencyField: React.FC<CurrenciesFieldProps> = ({
  name,
  enabled,
}) => {
  const { t } = useTranslation();
  const [systemCurrencies, setSystemCurrencies] = useState([]);
  useEffect(() => {
    const fetchSystemCurrencies = async () => {
      const { data: systemCurrencies } = await request({
        method: 'GET',
        path: `/1/system/currencies`,
      });

      setSystemCurrencies(systemCurrencies);
    };
    fetchSystemCurrencies();
  }, []);
  return (
    <FieldArray
      name={name}
      render={(helpers) => {
        const values = get(helpers.form.values, name);
        return (
          <>
            <Table style={{ tableLayout: 'fixed' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Currency</span>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <span>Manual Invoicing Fee</span>{' '}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {values?.map(
                  (
                    currency: {
                      id?: string;
                      currency: string;
                      manualInvoicingFee: string;
                    },
                    i: number
                  ) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <SelectField
                            name={`${name}.${i}.currency`}
                            disabled={!!currency.id}
                            required
                            options={systemCurrencies.map(({ currency }) => ({
                              key: currency,
                              text: currency,
                              value: currency,
                            }))}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.manualInvoicingFee`}
                            type="number"
                            min={0}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  }
                )}
              </Table.Body>
            </Table>
            {enabled && (
              <Button
                as="button"
                icon="plus"
                label={t('editBillingPlan.addPrice', 'Add currency')}
                onClick={() => {
                  helpers.push({
                    id: undefined,
                    currency: '',
                    manualInvoicingFee: undefined,
                    active: false,
                  });
                }}
              />
            )}
            <Message info>
              <Message.Content>
                {t(
                  'editProvider.currenciesInfo',
                  'At least one currency must be configured. Enabling the Multi Currency platform module below will allow additional currencies to be added.'
                )}
              </Message.Content>
            </Message>
          </>
        );
      }}
    />
  );
};

const fetchAvailableProviderFeatures = async (
  providerId: string
): Promise<{
  data: string[];
}> => {
  const { data } = await request({
    method: 'GET',
    path: `/1/platform-modules/${providerId}`,
  });
  return {
    data: data
      .map((m: any) => m.features)
      .flat()
      .map((f: any) => {
        return {
          id: f.key,
          value: f.key,
          name: f.name,
        };
      })
      .sort((a: any, b: any) => (a.id > b.id ? -1 : 1)),
  };
};

const fetchBillingEntity = (id: string) => {
  return request({
    method: 'GET',
    path: `/1/billing-entities/${id}`,
  });
};

interface Props {
  onSave?(): void;

  onSubmit(provider: Partial<Provider>): Promise<void>;

  provider?: Partial<Provider>;
}

const ProviderForm: React.FC<Props> = ({ provider, onSave, onSubmit }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showBillingByEflux, setShowBillingByEflux] = useState(true);

  const countriesOptions = Array.from(
    // this is temporary until the getCountryCodeMap actually returns a Map
    Object.entries(countries.getCountryCodeMap()),
    ([name, value]) => {
      return {
        text: value,
        value: name,
      };
    }
  );

  const { t } = useTranslation();
  const isUpdate = !!provider?.id;

  const submitHandler = async (
    formValues: Partial<Provider>,
    formikBag: any
  ) => {
    // don't save the api key if it's a sanitized
    if (formValues.postmarkApiKey?.includes('*')) {
      delete formValues.postmarkApiKey;
    }

    if (formValues.parentProvider || formValues.parentProviderId) {
      delete formValues.enableBillingByEflux;
    }
    try {
      await onSubmit(formValues);
      if (typeof onSave === 'function') {
        onSave();
      }
      setHasSubmitted(true);
    } catch (error: any) {
      setHasSubmitted(false);
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={(formValues, formikBag) =>
          submitHandler(formValues, formikBag)
        }
        validationSchema={Yup.object({
          name: Yup.string().required(
            t('editProvider.validation.nameRequired', 'Name is required')
          ),
          platformName: Yup.string().required(
            t(
              'editProvider.validation.platformNameRequired',
              'Platform Name is required'
            )
          ),
          slug: Yup.string().required(
            t('editProvider.validation.slugRequired', 'Slug is required')
          ),
          customDomain: Yup.string().when('enableSingleSignOn', {
            is: true,
            then: (schema) => {
              return schema.required(
                t(
                  'editProvider.validation.customDomainRequired',
                  'Custom domain is required'
                )
              );
            },
          }),
          billingEntityId: Yup.string()
            .optional()
            .when('enableInvoicing', {
              is: true,
              then: (schema) => {
                return schema.required(
                  t(
                    'editProvider.validation.billingEntityIdRequired',
                    'Billing Entity is required when enable invoicing is selected!'
                  )
                );
              },
            }),
        })}
        initialValues={provider || {}}>
        {({
          status,
          values,
          dirty,
          isSubmitting,
          handleSubmit,
          isValid,
          setFieldValue,
        }) => {
          useEffect(() => {
            if (values.billingEntityId) {
              fetchBillingEntity(values.billingEntityId).then(
                (billingEntity) => {
                  const billingEntityTaxResidencyCountryCode =
                    billingEntity.data.taxResidencyCountryCode;

                  if (
                    billingEntityTaxResidencyCountryCode !==
                    values.taxResidenceCountryCode
                  ) {
                    setFieldValue(
                      'taxResidenceCountryCode',
                      billingEntityTaxResidencyCountryCode
                    );
                  }
                }
              );
            }
          }, [values.billingEntityId, setFieldValue]);

          useEffect(() => {
            if (values.parentProviderId || values.parentProvider) {
              setShowBillingByEflux(false);
            } else {
              setShowBillingByEflux(true);
            }
          }, [values.parentProviderId, values.parentProvider]);

          return (
            <Form id="edit-provider-form">
              <InputField
                name="name"
                label={t('editProvider.name', 'Name')}
                required
              />
              <InputField
                name="platformName"
                label={t('editProvider.platformName', 'Platform Name')}
                required
              />
              <InputField
                name="slug"
                label={t('editProvider.humanReadableID', 'Human readable ID')}
                required
                disabled={isUpdate}
              />
              <SearchDropdownField
                name="parentProviderId"
                key={values.parentProviderId}
                label={t('editProvider.parentProvider', 'Parent Provider')}
                clearable
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/providers/search',
                    method: 'POST',
                    body: body,
                  });
                }}
              />

              <SearchDropdownField
                name="billingEntityId"
                label={t('editProvider.billingEntity', 'Billing Entity')}
                key={values.billingEntityId}
                clearable
                getOptionLabel={(item) =>
                  `${item.name} ${item.taxResidencyCountryCode}`
                }
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/billing-entities',
                    method: 'GET',
                  });
                }}
              />

              <Divider />
              <Header
                icon="file-invoice-dollar"
                content={t('editProvider.billing', 'Billing')}
                subheader={t(
                  'editProvider.billingSubHeader',
                  'Invoicing, ERP, emailing, products'
                )}
                as="h4"
                dividing
              />
              <Segment basic>
                <CountriesField
                  label="Tax Residence Country Code"
                  name="taxResidenceCountryCode"
                  required
                  standard="alpha-3"
                  lowerCase={false}
                  whitelist={['NLD', 'DEU', 'FRA']}
                  countryNameLabels={false}
                  disabled={Boolean(values.billingEntityId)}
                />
                <SelectField
                  multiple
                  label={t(
                    'editProvider.enabledChargeProducts',
                    'Enabled Charge Products'
                  )}
                  name="enabledChargeProducts"
                  options={[
                    {
                      key: 'card',
                      text: 'Card',
                      value: 'card',
                    },
                    {
                      key: 'tag',
                      text: 'Tag',
                      value: 'tag',
                    },
                    {
                      key: 'charge-station',
                      text: 'Charge Station',
                      value: 'charge-station',
                    },
                  ]}
                />

                {showBillingByEflux && (
                  <CheckboxField
                    label={t(
                      'editProvider.enableBillingByEflux',
                      'Billing by E-flux?'
                    )}
                    name="enableBillingByEflux"
                  />
                )}

                <div>
                  <Group>
                    <CheckboxField
                      label={t(
                        'editProvider.enableInvoicing',
                        'Enable Invoicing?'
                      )}
                      name="enableInvoicing"
                    />
                    {values.enableInvoicing && (
                      <p style={{ fontStyle: 'italic' }}>
                        {t(
                          'editProvider.enableInvoicingInfo',
                          'Note a billing entity must be provided above if this option is selected!'
                        )}
                      </p>
                    )}
                  </Group>
                </div>

                <CheckboxField
                  label={t(
                    'editProvider.enableManualPay',
                    'Enable Manual Pay?'
                  )}
                  name="enableManualPay"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableChargeAtHome',
                    'Enable "Charge At Home"'
                  )}
                  name="enableChargeAtHome"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableSelfSignup',
                    'Enable "Self Signup"'
                  )}
                  name="enableSelfSignup"
                />

                {values.enableInvoicing && (
                  <>
                    <CheckboxField
                      label={t(
                        'editProvider.enableInvoiceEmailsMspUsage',
                        'Enable automatic emails for MSP usage invoices?'
                      )}
                      name="enableInvoiceEmailsMspUsage"
                    />
                    <CheckboxField
                      label={t(
                        'editProvider.enableInvoiceEmailsCpoUsage',
                        'Enable automatic emails for CPO usage invoices?'
                      )}
                      name="enableInvoiceEmailsCpoUsage"
                    />
                    <CheckboxField
                      label={t(
                        'editProvider.enableInvoiceEmailsCpoCredit',
                        'Enable automatic emails for CPO credit invoices?'
                      )}
                      name="enableInvoiceEmailsCpoCredit"
                    />
                  </>
                )}
              </Segment>

              <Divider />

              <Header
                icon="money-bill-1"
                content={t('editProvider.currencies', 'Currencies')}
                subheader={t(
                  'editProvider.currenciesSubHeader',
                  'Currencies made available to accounts and billing plans'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <ProviderCurrencyField
                  enabled={values.enableMultiCurrency}
                  name="currencies"
                />
              </Segment>

              <Divider />

              <Header
                icon="puzzle-piece"
                content={t('editProvider.modules', 'Platform Modules')}
                subheader={t(
                  'editProvider.modulesSubheader',
                  'Platform Modules Configuration'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <CheckboxField
                  label={t(
                    'editProvider.enableSocialLogin',
                    'Enable social login'
                  )}
                  name="enableSocialLogin"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableAccountTiers',
                    'Enable Account Tiers Configuration'
                  )}
                  name="enableAccountTiers"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableAdvancedBilling',
                    'Enable Advanced Billing Options (split/VVE billing)'
                  )}
                  name="enableAdvancedBilling"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableAdvancedTariffs',
                    'Enable Advanced Tariff Support (scheduled, idle, dynamic)'
                  )}
                  name="enableAdvancedTariffs"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableMultiCurrency',
                    'Enable Multi Currency Support'
                  )}
                  name="enableMultiCurrency"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableSmartCharging',
                    'Enable Pairing an EVSE to a Smart Charging Provider'
                  )}
                  name="enableSmartCharging"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableAlternativePaymentMethods',
                    'Enable Support for Payment Terminals (e.g. Alpitronic, Payter)'
                  )}
                  name="enableAlternativePaymentMethods"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableTokenBatches',
                    'Enables Generation of Token Batches.'
                  )}
                  name="enableTokenBatches"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enablePSPIntegrations',
                    'Enables integration of payments through Payment Service Providers.'
                  )}
                  name="enablePSPIntegrations"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableSandboxMode',
                    'Enables Sandbox mode.'
                  )}
                  name="enableSandboxMode"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableChargingStationUI',
                    'Enable Charging Station UI.'
                  )}
                  name="enableChargingStationUI"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableSingleSignOn',
                    'Enable Single Sign On (alpha, not for production use)'
                  )}
                  name="enableSingleSignOn"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableBulkManagement',
                    'Enable Charge Station Bulk Management'
                  )}
                  name="enableBulkManagement"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableCustomFees',
                    'Enable Custom Fees'
                  )}
                  name="enableWhitelabelFees"
                />

                <Message info>
                  <Message.Content>
                    {t(
                      'editProvider.moduleConfigurationInfo',
                      "The enablement of a platform module does not make the feature immediately available to provider's accounts. To make the feature available to an account it needs to either be added to the list of default features below, or added to an account tier, or individually to an account via Edit Account functionality."
                    )}
                    <br />
                    <br />
                    <strong>
                      {t(
                        'editProvider.moduleConfigurationReloadInfo',
                        "If you've enabled any new modules above, you need to save this form to see the new features available in the list below."
                      )}
                    </strong>
                  </Message.Content>
                </Message>

                <SearchDropdownField
                  objectMode={false}
                  multiple
                  onDataNeeded={async () =>
                    values.id
                      ? fetchAvailableProviderFeatures(values.id)
                      : { data: [] }
                  }
                  name="defaultPlatformFeaturesOnAllAccounts"
                  label={t(
                    'editProvider.defaultPlatformFeaturesOnAllAccounts',
                    'Default features enabled for every account'
                  )}
                />
              </Segment>

              <Divider />

              <Header
                icon="paintbrush"
                content={t('editProvider.lookAndFeel', 'Look & Feel')}
                subheader={t(
                  'editProvider.lookAndFeelSubHeader',
                  'Custom logo, icon and colors'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <InputField
                  name="invertedLogoUrl"
                  label={t(
                    'editProvider.logoDarkBackground',
                    'Logo URL (Dark Background)'
                  )}
                />

                <InputField
                  name="logoUrl"
                  label={t(
                    'editProvider.logoLightBackground',
                    'Logo URL (Light Background)'
                  )}
                />

                <InputField
                  name="faviconUrl"
                  label={t('editProvider.faviconURL', 'Favicon URL')}
                />
                <Form.Group widths="equal">
                  <InputField
                    name="primaryColorHex"
                    label={t(
                      'editProvider.primaryColor',
                      'Primary Color (Hexadecimal RGB)'
                    )}
                  />
                  <InputField
                    name="primaryColorDarkHex"
                    label={t(
                      'editProvider.primaryColorDark',
                      'Dark Primary Color (Hexadecimal RGB)'
                    )}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <InputField
                    name="secondaryBackgroundColorHex"
                    label={t(
                      'editProvider.secondaryBackgroundColor',
                      'Secondary Background Color (Hexadecimal RGB)'
                    )}
                    type="text"
                  />
                  <InputField
                    name="secondaryBackgroundColorDarkHex"
                    label={t(
                      'editProvider.secondaryBackgroundColorDark',
                      'Dark Secondary Background Color (Hexadecimal RGB)'
                    )}
                    type="text"
                  />
                </Form.Group>
              </Segment>

              <Divider />

              <Header
                icon="file-contract"
                content={t('editProvider.privacyHeader', 'Privacy and Terms')}
                subheader={t(
                  'editProvider.sprivacySubHeader',
                  'Legal documents and Privacy features'
                )}
                as="h4"
                dividing
              />
              <Segment basic>
                <InputField
                  name="tosUrl"
                  label={t('editProvider.tosUrl', 'Terms of Service Url')}
                  type="url"
                />

                <InputField
                  name="privacyUrl"
                  label={t('editProvider.privacyUrl', 'Privacy Url')}
                  type="url"
                />

                <InputField
                  name="sepaUrl"
                  label={t('editProvider.sepaUrl', 'SEPA Url')}
                  type="url"
                />

                <CheckboxField
                  label={t(
                    'editProvider.enableWebAnalytics',
                    'Enable Web Analytics'
                  )}
                  name="enableWebAnalytics"
                />
              </Segment>

              <Divider />

              <Header
                icon="phone"
                content={t('editProvider.support', 'Support')}
                subheader={t(
                  'editProvider.supportSubHeader',
                  'Support and Contact Information'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <InputField
                  name="supportEmail"
                  label={t('editProvider.supportEmail', 'Support Email')}
                />

                <InputField
                  name="supportUrl"
                  label={t('editProvider.supportURL', 'Support URL')}
                />

                <InputField
                  name="invoiceSupportUrl"
                  label={t(
                    'editProvider.invoiceSupportUrl',
                    'Invoice Support URL'
                  )}
                />

                <InputField
                  name="invoiceSupportEmail"
                  label={t(
                    'editProvider.invoiceSupportEmail',
                    'Invoice Support Email'
                  )}
                />

                <InputField
                  name="supportPhoneNo"
                  label={t('editProvider.supportPhoneNo', 'Support Phone No')}
                />

                <InputField
                  name="address"
                  placeholder="Company Name, Street <Number> ... "
                  label={t('editProvider.address', 'Address')}
                />

                <InputField
                  name="companyInfo"
                  placeholder="KVK No, BTW No, ..."
                  label={t('editProvider.companyInfo', 'Company Info')}
                />

                <InputField
                  name="evseUrl"
                  label={t(
                    'editProvider.evseUrl',
                    'Charge Station Support URL'
                  )}
                  type="url"
                />
              </Segment>

              <Divider />

              <Header
                icon="code"
                content={t('editProvider.apiSettings', 'API Settings')}
                subheader={t(
                  'editProvider.apiSettings',
                  'API and Technical Settings'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <CheckboxField
                  label={t(
                    'editProvider.enableApiAccess',
                    'Enable API access?'
                  )}
                  name="enableApiAccess"
                />

                <CheckboxField
                  name="enableMspSessionDeletion"
                  label="Enable MSP session deletion"
                />

                <DropdownField
                  name="evseIssueEmails"
                  selection
                  multiple
                  options={
                    values.evseIssueEmails?.map((value) => {
                      return {
                        value,
                        text: value,
                      };
                    }) || []
                  }
                  label="EVSE Issues E-mail Recipients"
                />

                <SelectField
                  label={t(
                    'editProvider.supportedServices',
                    'Supported Services'
                  )}
                  multiple
                  name="supportedServices"
                  options={[
                    {
                      text: 'Exact',
                      key: 'exact',
                      value: 'exact',
                    },
                    {
                      text: 'Exact Credit',
                      key: 'exactCredit',
                      value: 'exactCredit',
                    },
                    {
                      text: 'Intercom',
                      key: 'intercom',
                      value: 'intercom',
                    },
                    {
                      text: 'Netsuite',
                      key: 'netsuite',
                      value: 'netsuite',
                    },
                  ]}
                />
              </Segment>

              <Divider />

              <Header
                icon="network-wired"
                content={t('editProvider.dnsSettings', 'DNS Settings')}
                subheader={t(
                  'editProvider.dnsSettingsSubheader',
                  'Custom Domain Configuration'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <InputField
                  name="customDomain"
                  label={t(
                    'editProvider.customDomain',
                    'Custom Domain (excluding http://)'
                  )}
                  validateImmediately
                />

                <Message info>
                  <Message.Header>
                    {t(
                      'editProvider.configuringYourDNS',
                      'Configuring Your DNS'
                    )}
                  </Message.Header>
                  <Message.Content>
                    <b>
                      In order to use a subdomain as the primary destination for
                      the dashboard - DNS needs to be configured.
                    </b>
                    <br />
                    <br />
                    Using Cloudflare managed SSL: Add a CNAME record (e.g.
                    ev.provider.com) with value <code>dashboard.e-flux.nl</code>
                    . This requires the customer to add TXT records to their
                    DNS. Speak to onboarding team.
                  </Message.Content>
                </Message>
              </Segment>

              <Divider />

              <Header
                icon="envelope"
                content={t('editProvider.emailDelivery', 'E-Mail Delivery')}
                subheader={t(
                  'editProvider.emailDeliverySubHeader',
                  'Custom E-Mail Origin'
                )}
                as="h4"
                dividing
              />

              <Segment basic>
                <p>
                  {t(
                    'editProvider.emailDeliveryDescription',
                    'This requires a custom Postmark configuration (with a verified from address)'
                  )}
                </p>
                <InputField
                  name="postmarkApiKey"
                  label={t('editProvider.postmarkApiKey', 'Postmark API Key')}
                  type="text"
                />

                <InputField
                  name="postmarkFrom"
                  label={t(
                    'editProvider.postmarkFromEmail',
                    'Postmark From E-Mail'
                  )}
                  type="text"
                />
              </Segment>

              <Divider />

              <Header
                icon="bolt"
                content={t('editProvider.chargeStations', 'Charge Stations')}
                subheader={t(
                  'editProvider.chargeStationsSubheader',
                  'Charge Station Configuration'
                )}
                as="h4"
                dividing
              />
              <Segment basic>
                <CheckboxField
                  label={t(
                    'editProvider.enablePairChargeCard',
                    'Enable "Pair Charge Card"'
                  )}
                  name="enableChargeStationPairChargeCard"
                />
                <Message info>
                  <Message.Content>
                    {t(
                      'editProvider.enablePairChargeCardExtra',
                      'This option is only supported for chargers of the following vendors: DiniTech'
                    )}
                  </Message.Content>
                </Message>
                <FeatureFlag feature="evsecontroller-inactivity-interval-check">
                  <>
                    <TimeField
                      label={t(
                        'editProvider.chargingStationInactivityCheckIntervalInSeconds',
                        'Charging Station Inactivity Check Interval'
                      )}
                      name="chargingStationInactivityCheckIntervalInSeconds"
                    />
                    <Message info>
                      <Message.Content>
                        {t(
                          'editProvider.chargingStationInactivityCheckIntervalInfo',
                          'Default value is 24 hours and minimum value is 10 minutes. If a charging station remains inactive for this period, an EVSE Issue will be created.'
                        )}
                      </Message.Content>
                    </Message>
                  </>
                </FeatureFlag>
              </Segment>

              <Divider />

              <Header
                icon="diagram-project"
                content={t('editProvider.roaming', 'Roaming')}
                subheader={t(
                  'editProvider.roamingSubheader',
                  'Roaming Configuration'
                )}
                as="h4"
                dividing
              />
              <Segment basic>
                <CheckboxField
                  label={t(
                    'editProvider.enableSubOperatorName',
                    'Enable Sub Operator Name at provider level'
                  )}
                  name="enableSubOperatorName"
                />

                {values.enableSubOperatorName && (
                  <InputField
                    name="roamingSubOperatorName"
                    label={t(
                      'editProvider.roamingSubOperatorName',
                      'Roaming Sub Operator Name'
                    )}
                  />
                )}
              </Segment>

              {values.enableChargingStationUI && (
                <>
                  <Divider />
                  <Header
                    icon="charging-station"
                    content={t(
                      'editProvider.chargeStationUISupport',
                      'Charge Station'
                    )}
                    subheader={t(
                      'editProvider.chargeStationUISupportSubHeader',
                      'Additional configuration for charging station scan-to-pay and support'
                    )}
                    as="h4"
                    dividing
                  />
                  <Divider />
                  <Segment basic style={{ paddingTop: 0 }}>
                    <Header as="h5" style={{ marginTop: 0 }}>
                      {t(
                        'editProvider.csuiSupportHeader',
                        'Charge station: Support'
                      )}
                    </Header>
                    <Message info>
                      <Message.Content>
                        {t(
                          'editProvider.csuiSupportText',
                          'URLs provided here will be displayed as card links under Support section within the Charge Station UI'
                        )}
                      </Message.Content>
                    </Message>
                    <InputField
                      name="csuiFaqUrl"
                      label={t('editProvider.csuiFaqUrl', 'FAQ URL')}
                    />
                    <InputField
                      required
                      name="supportUrl"
                      label={t('editProvider.csuiSupportUrl', 'Support URL')}
                    />
                    <InputField
                      name="csuiAboutUrl"
                      label={t('editProvider.csuiAboutUrl', 'About us URL')}
                      type="text"
                    />
                    <CheckboxField
                      name="csuiSupportEnableOwnNumbers"
                      label={t(
                        'editProvider.csuiSupportEnableOwnNumbers',
                        'Enable own phone numbers'
                      )}
                    />
                    <Message info>
                      <Message.Content>
                        {t(
                          'editProvider.csuiEnableOwnNumbersText',
                          'Custom phone numbers will be displayed in last step of self-support options flow.'
                        )}
                      </Message.Content>
                    </Message>
                    <InputField
                      name="csuiSupportPhoneNumberEN"
                      label={t(
                        'editProvider.csuiSupportPhoneNumberEN',
                        'Support Phone Number (EN)'
                      )}
                      type="tel"
                    />
                    <InputField
                      name="csuiSupportPhoneNumberNL"
                      label={t(
                        'editProvider.csuiSupportPhoneNumberNL',
                        'Support Phone Number (NL)'
                      )}
                      type="tel"
                    />
                  </Segment>
                </>
              )}

              <Segment basic>
                <Divider />
                <Header
                  icon="mobile"
                  content={t('editProvider.mobileAppSettings', 'Mobile app')}
                  subheader={t(
                    'editProvider.mobileAppSettingsSubtitle',
                    'Mobile app configuration'
                  )}
                  as="h4"
                  dividing
                />
                <InputField
                  name="androidAppUrl"
                  label={t('editProvider.androidAppUrl', 'Android app URL')}
                />

                <InputField
                  name="iosAppUrl"
                  label={t('editProvider.iosAppUrl', 'iOS app URL')}
                />

                <DropdownField
                  name="appSupportedCountries"
                  search
                  selection
                  multiple
                  options={countriesOptions}
                  label={t(
                    'editProvider.appSupportedCountries',
                    'Allow app downloads only in these countries. Leave blank for all'
                  )}
                />

                <InputField
                  name="deleteAccountUrl"
                  label={t(
                    'editProvider.deleteAccountUrl',
                    'Delete Account URL'
                  )}
                />
              </Segment>
              {status && (
                <Message error>
                  <p>{status}</p>
                </Message>
              )}
              {hasSubmitted && (
                <Message success>
                  <p>
                    {t(
                      'editProvider.saved',
                      'Provider configuration saved successfully'
                    )}
                  </p>
                </Message>
              )}
              <Button
                as="button"
                loading={isSubmitting}
                role="button"
                aria-label={isUpdate ? t('button.update') : t('button.create')}
                primary
                disabled={isSubmitting || !isValid || !dirty}
                content={isUpdate ? t('button.update') : t('button.create')}
                onClick={handleSubmit}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

ProviderForm.defaultProps = {
  provider: {
    enableAccountTiers: false,
    enableApiAccess: false,
    enableInvoicing: false,
    enabledChargeProducts: ['card', 'tag', 'charge-station'],
    enableInvoiceEmailsMspUsage: false,
    enableInvoiceEmailsCpoUsage: false,
    enableInvoiceEmailsCpoCredit: false,
    enableMspSessionDeletion: false,
    enableAdvancedBilling: false,
    enableAdvancedTariffs: false,
    enableMultiCurrency: false,
    enableSubOperatorName: false,
    enableBulkManagement: false,
    defaultPlatformFeaturesOnAllAccounts: [],
    supportedPaymentMethods: ['autopay'],
    currencies: [
      {
        id: undefined,
        currency: 'EUR',
        manualInvoicingFee: undefined,
        active: true,
      },
    ],
  },
};

export default ProviderForm;
